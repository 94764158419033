$theme-color-base-light-grey: #f8f8f8;
$theme-color-base-grey: #777;
$theme-color-base-black: #111;
$theme-color-base-white: #fff;

// $font-primary: 'Prompt', sans-serif;

// primary

$color-primary: #777777;
 $font-primary: 'Prompt', sans-serif;
$font-storytella: 'Storytella', sans-serif;
$font-permanent-marker: 'Permanent Marker', cursive;

// colors

$orange: #efc940;
$blue: #16385f;
$dblue: #282a43;
$grey: #c4c4c4;
$title: #4a4c70;

