/* prompt-100 - latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local(''),
       url('../fonts/prompt-v10-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
}
/* prompt-200 - latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local(''),
       url('../fonts/prompt-v10-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
}
/* prompt-300 - latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local(''),
       url('../fonts/prompt-v10-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
}
/* prompt-regular - latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(''),
       url('../fonts/prompt-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
}
/* prompt-500 - latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(''),
       url('../fonts/prompt-v10-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
}
/* prompt-600 - latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local(''),
       url('../fonts/prompt-v10-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
}
/* prompt-700 - latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(''),
       url('../fonts/prompt-v10-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
}
/* prompt-800 - latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local(''),
       url('../fonts/prompt-v10-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
}
/* prompt-900 - latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local(''),
       url('../fonts/prompt-v10-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
}
