/* You can add global styles to this file, and also import other style files */
@import './styles/base/variables/colors';
@import './styles/base/mixins/breakpoints.scss';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'swiper/css';
body {
  color: $theme-color-base-black;
  font-size: 14px;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  font-family: $font-primary;
  font-weight: 300;

  &.modal-open {
    @apply overflow-hidden;
  }
  .swiper-pagination-bullet-active {
    @apply bg-blue-900;
  }
  b {
    color: $blue;
    font-weight: bold;
  }
}

.info {
  color: $blue;

  &__wrapper {
    @apply grid grid-cols-1 sm:grid-cols-2 mt-12;
  }

  &__headline {
    @include breakpoint('md') {
      border-right: 1px solid $blue;
    }
  }

  &__text {
    @include breakpoint('md') {
      padding-left: 50px;
    }

    p {
      color: $blue;
    }
  }
}

.title {
  p {
    @apply text-5xl text-blue-900;
  }
}

@layer base {
  .imprint {
    p {
      @apply mt-5;
      white-space: pre-line;
    }
    b {
      @apply text-gray-900;
    }
  }
  p {
    @apply text-base text-gray-500;
  }

  pre {
    @apply font-primary text-sm text-gray-500 whitespace-pre-wrap;
  }

  a {
    @apply text-base text-gray-500 tracking-widest text-base font-medium hover:text-gray-700;
  }
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl text-black font-bold;
  }
  h3 {
    @apply font-alt uppercase tracking-widest text-lg;
  }
  h4 {
    @apply font-alt uppercase tracking-widest text-base;
  }
  h5 {
    @apply font-alt text-sm uppercase tracking-widest;
  }

  h6 {
    @apply font-primary text-sm uppercase tracking-widest font-bold;
  }
  iframe {
    @apply w-full h-full;
  }

  button {
    @apply text-xs px-10 py-3 bg-transparent font-semibold border-2 border-solid border-blue-900 rounded-sm tracking-widest text-gray-600 transition-colors hover:text-white hover:bg-blue-900 hover:font-semibold;
  }

  label {
    @apply block text-sm font-medium text-gray-700;
  }

  input {
    @apply focus:ring-blue-900 focus:border-blue-900 block w-full  text-sm leading-10 border-gray-300 rounded-md border mt-1 pl-4;
  }

  input[type='checkbox'] {
    @apply focus:ring-blue-800 h-4 w-4 text-blue-900 border-gray-300 rounded;
  }

  select {
    @apply mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-900 focus:border-blue-900 sm:text-sm;
  }

  ::placeholder {
    @apply text-xs;
  }
}
